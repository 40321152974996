import React, { Component } from 'react'
import { graphql } from 'gatsby'
import moment from 'moment'
import { INews } from '~models'
import { Header } from '~views/Header'
import { Container, Button } from '~components'
import styles from './styles.module.scss'
import { Footer } from '~views/Footer'
import { withStore } from '~libs/mobx'

interface NewsPostProps {
  data: {
    wordpressWpNews: {
      id: number
      date: string
      acf: INews
    }
  }
}

class NewsPost extends Component<NewsPostProps> {
  render() {
    const { acf, date } = this.props.data.wordpressWpNews
    return (
      <React.Fragment>
        <Header />
        <Container className={styles.wrapper}>
          <div className="narrow-width">
            <h1>{acf.title}</h1>
            <p className={[styles.date, 'secondary-text'].join(' ')}>
              {moment(date).format('DD MMMM YYYY')}
            </p>
          </div>
          {acf.images && <img src={acf.images[0].localFile.childImageSharp.large.src} />}
          <p className={'small-text narrow-width'}>{acf.text}</p>

          <Button onClick={() => window.history.back()} className={styles.back} secondary>
            Tillbaka
          </Button>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default withStore(NewsPost)

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpNews(id: { eq: $id }) {
      ...newsFields
    }
  }
`
